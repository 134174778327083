import { useState, MouseEvent, useMemo } from "react"
import MoreVertIcon from '@mui/icons-material/MoreVert'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft'
import SearchIcon from '@mui/icons-material/Search'
import {
  Popper,
  Paper,
  ClickAwayListener,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Avatar,
  Typography,
} from "@mui/material"
import { ReamarkListItemButton, ReamarSearchField, StyledIconButton } from "./RemarkTableMenu.styles"
import { RemarkTableMenuProps, SubMenuData } from "."
import { RemarkTableMenuData } from "../../RemarksTable.types"

const users = [
  {
    name: 'Иван Иванов',
    id: 1,
    position: 'Менеджер коллаборации',
  },
  {
    name: 'Сергей Сергеев',
    id: 2,
    position: 'Прототипный тестер',
  },
  {
    name: 'Петр Петров',
    id: 3,
    position: 'Экзорцист проекта',
  },
]

export const RemarkTableMenu = ({ data, disabled }: RemarkTableMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [subMenuAnchor, setSubMenuAnchor] = useState<null | HTMLDivElement>(null)
  const [subMenu, setSubMenu] = useState<SubMenuData | null>(null)
  const [search, setSearch] = useState("")
  const itemsWithSubMenu = ['Назначить исполнителя', 'Назначить ответственного', 'Сменить исполнителя', 'Сменить ответственного']

  const filteredUsers = useMemo(() => {
    const filtered =
      users.filter((user) => user.name.toLowerCase().includes(search.toLowerCase()))
      || users.filter((user) => user.position.toLowerCase().includes(search.toLowerCase()))
    return filtered
  }, [search, users])

  const handleMenuClose = () => {
    setAnchorEl(null)
    setSubMenu(null)
  }

  const handleMenuOpen = (e: MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      e.stopPropagation()
      if (anchorEl) {
        handleMenuClose()
      } else {
        setAnchorEl(e.currentTarget)
      }
    }
  }

  const onSelectMenuItem = (e: MouseEvent, action: () => void) => {
    e.stopPropagation()
    action()
    handleMenuClose()
  }

  const handleAssignExecutor = (e: MouseEvent<HTMLDivElement>, item: RemarkTableMenuData) => {
    setSubMenuAnchor(e.currentTarget)
    setSubMenu({ item: item.itemName, action: item.action })
  }

  const handleSelectUser = (user: string, e: MouseEvent) => {
    e.stopPropagation()
    subMenu?.action(user)
    handleMenuClose()
  }

  return (
    <>
      <StyledIconButton onClick={handleMenuOpen} disabled={disabled}>
        <MoreVertIcon fontSize="small" color='primary' />
      </StyledIconButton>
      <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start">
        <Paper>
          <ClickAwayListener onClickAway={handleMenuClose}>
            <List>
              {data && data.map((item) =>
                itemsWithSubMenu.includes(item.itemName) ? (
                  <ReamarkListItemButton onClick={e => e.stopPropagation()} onMouseEnter={(e) => handleAssignExecutor(e, item)} sx={{ paddingLeft: '16px!important' }}>
                    <ArrowLeftIcon fontSize="small" />
                    {item.itemName}
                  </ReamarkListItemButton>
                ) : (
                  <ReamarkListItemButton onClick={(e) => onSelectMenuItem(e, item.action)} onMouseEnter={() => setSubMenu(null)}>
                    {item.itemName}
                  </ReamarkListItemButton>
                )
              )}
            </List>
          </ClickAwayListener>
        </Paper>
      </Popper>
      <Popper open={subMenu !== null} anchorEl={subMenuAnchor} placement="right-start">
        <Paper sx={{ width: 320 }} onMouseLeave={() => setSubMenu(null)}>
          <>
            <ReamarSearchField
              type="search"
              placeholder="Поиск"
              value={search}
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
              onClick={(e) => e.stopPropagation()}
              InputProps={{
                startAdornment: (
                  <SearchIcon fontSize="medium" />
                )
              }}
            />
            <List>
              {filteredUsers.map((user) => (
                <ListItem disablePadding key={user.id}>
                  <ListItemButton
                    onClick={(e) => handleSelectUser(user.name, e)}
                    sx={{ "&:hover": { backgroundColor: "#f0f0f0" } }}
                  >
                    <Stack direction='row' spacing={1}>
                      <Avatar sx={{ height: 32, width: 32 }} src={undefined}></Avatar>
                      <Stack>
                        <Typography fontSize={14}>{user.name}</Typography>
                        <Typography variant="body2" fontSize={12}>{user.position}</Typography>
                      </Stack>
                    </Stack>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </>
        </Paper>
      </Popper>
    </>
  )
}
