import { api } from '../api'
import {
  AddRemarkMessageRequest,
  CreateRemarkRequest,
  CreateRemarkResponse,
  DeleteRemarkRequest,
  DownloadAttachmentRequest,
  DownloadRemarkMailRequest,
  EditRemarkRequest,
  EditRemarkResponse,
  ExportRemarksRequest,
  GetAmountRemarksRequest,
  GetAmountRemarksResponse,
  GetProjectGroupedRemarksResponse,
  GetProjectRemarksRequest,
  GetProjectRemarksResponse,
  GetProjectTomsRequest,
  GetProjectTomsResponse,
  GetRemarkFilterGroupsRequest,
  GetRemarkFilterGroupsResponse,
  GetRemarkMailTomsRequest,
  GetRemarkMailTomsResponse,
  GetRemarkOnIdRequest,
  GetRemarkOnIdResponse,
  GetRemarkResponsibleRequest,
  GetRemarkResponsibleResponse,
  GetRemarkTypesListRequest,
  GetRemarkTypesListResponse,
  GetRemarksWidgetRequest,
  GetRemarksWidgetResponse,
  GetResponsibleListRequest,
  GetResponsibleListResponse,
  GetTomRemarksRequest,
  GetTomRemarksResponse,
  ManualSendRemarkRequest,
  ManualSendRemarkResponse,
  RemarkAccessRequest,
  RemarkAccessResponse,
} from './api.types'

export const remarkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getTomRemarks: build.query<GetTomRemarksResponse, GetTomRemarksRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/remarks/tom/${tomId}/list`,
        method: 'GET',
        params,
      }),
      providesTags: ['Remarks'],
    }),
    getProjectRemarks: build.query<GetProjectRemarksResponse, GetProjectRemarksRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/project/${projectId}/remarks/list`,
        method: 'GET',
        params,
      }),
      providesTags: ['Remarks'],
    }),
    getProjectGroupedRemarks: build.query<GetProjectGroupedRemarksResponse, GetProjectRemarksRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/project/${projectId}/remarks/grouped-list`,
        method: 'GET',
        params,
      }),
      providesTags: ['Remarks'],
    }),
    getRemarkOnId: build.query<GetRemarkOnIdResponse, GetRemarkOnIdRequest>({
      query: ({ projectId, remarkId }) => ({
        url: `/project/${projectId}/remarks/${remarkId}/get`,
        method: 'GET',
      }),
      providesTags: ['RemarkOnId'],
    }),
    getRemarksWidget: build.query<GetRemarksWidgetResponse, GetRemarksWidgetRequest>({
      query: ({ projectId }) => ({
        url: `/project/${projectId}/remarks/widget`,
        method: 'GET',
      }),
      providesTags: ['RemarkWidget'],
    }),
    getAmountRemarks: build.query<GetAmountRemarksResponse, GetAmountRemarksRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/remarks/tom/${tomId}/filters`,
        method: 'GET',
        params,
      }),
      providesTags: ['RemarkAmount'],
    }),
    remarkAccess: build.query<RemarkAccessResponse, RemarkAccessRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/remarks/tom/${tomId}/access`,
        method: 'GET',
        params,
      }),
    }),
    getResponsibleList: build.query<GetResponsibleListResponse, GetResponsibleListRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/remarks/tom/${tomId}/responsible`,
        method: 'GET',
        params,
      }),
    }),
    getRemarkTypesList: build.query<GetRemarkTypesListResponse, GetRemarkTypesListRequest>({
      query: ({ projectId, tomId, ...params }) => ({
        url: `/project/${projectId}/remarks/tom/${tomId}/types`,
        method: 'GET',
        params,
      }),
      providesTags: ['RemarkTypes'],
    }),
    getRemarkFilterGroups: build.query<GetRemarkFilterGroupsResponse, GetRemarkFilterGroupsRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/project/${projectId}/remarks/filters`,
        method: 'GET',
        params,
      }),
      providesTags: ['RemarkGroups'],
    }),
    getProjectToms: build.query<GetProjectTomsResponse, GetProjectTomsRequest>({
      query: ({ projectId, ...params }) => ({
        url: `/project/${projectId}/remarks/toms`,
        method: 'GET',
        params,
      }),
    }),
    createRemark: build.mutation<CreateRemarkResponse, CreateRemarkRequest>({
      query: ({ projectId, tomId, type, file, ...body }) => {
        const formData = new FormData()
        Object.entries(body).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value))
        })
        if (file) {
          Array.from(file).forEach((file) => formData.append('file', file))
        }
        return {
          url: `/project/${projectId}/remarks/tom/${tomId}/create`,
          method: 'POST',
          params: { type },
          body: formData,
        }
      },
      invalidatesTags: [
        'Remarks',
        'RemarkTypes',
        'RemarkAmount',
        'RemarkWidget',
        'RemarkGroups',
        'AgreementHistory',
        'RemarkMailToms',
      ],
    }),
    editRemark: build.mutation<EditRemarkResponse, EditRemarkRequest>({
      query: ({ projectId, remarkId, file, deleteFileId, ...body }) => {
        const formData = new FormData()
        Object.entries(body).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value))
        })
        if (file) {
          Array.from(file).forEach((file) => formData.append('file', file))
        }
        if (deleteFileId && deleteFileId?.length > 0) {
          deleteFileId.forEach((id) => formData.append('deleteFileId', JSON.stringify(id)))
        }
        return {
          url: `/project/${projectId}/remarks/${remarkId}/update`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['Remarks', 'RemarkTypes', 'RemarkOnId'],
    }),
    addRemarkMessage: build.mutation<{}, AddRemarkMessageRequest>({
      query: ({ projectId, remarkId, file, ...body }) => {
        const formData = new FormData()
        Object.entries(body).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value))
        })
        if (file) {
          Array.from(file).forEach((file) => formData.append('file', file))
        }
        return {
          url: `/project/${projectId}/remarks/${remarkId}/messages/add`,
          method: 'POST',
          body: formData,
        }
      },
      invalidatesTags: ['Remarks', 'RemarkAmount', 'RemarkWidget', 'RemarkOnId'],
    }),
    downloadRemarkAttachment: build.mutation<Blob, DownloadAttachmentRequest>({
      query: ({ projectId, remarkId, attachId }) => ({
        url: `/project/${projectId}/remarks/${remarkId}/attachments/${attachId}/download`,
        method: 'POST',
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    deleteRemark: build.mutation<{}, DeleteRemarkRequest>({
      query: ({ projectId, remarkId }) => ({
        url: `/project/${projectId}/remarks/${remarkId}/delete`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Remarks', 'RemarkAmount', 'RemarkWidget', 'RemarkGroups', 'RemarkMailToms'],
    }),
    exportRemarks: build.query<Blob, ExportRemarksRequest>({
      query: ({ projectId, localDateTime, ...params }) => ({
        url: `/project/${projectId}/remarks/export-excel-file`,
        method: 'POST',
        body: { localDateTime },
        params,
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    downloadRemarkMail: build.query<Blob, DownloadRemarkMailRequest>({
      query: ({ projectId, mailId }) => ({
        url: `/project/${projectId}/remarks/mails/${mailId}/download/excel`,
        method: 'POST',
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    downloadRemarkMailAttachment: build.query<Blob, DownloadRemarkMailRequest>({
      query: ({ projectId, mailId }) => ({
        url: `/project/${projectId}/remarks/mails/${mailId}/download/attachment`,
        method: 'POST',
        responseHandler: (response: any) => response.blob(),
      }),
    }),
    getRemarkMailToms: build.query<GetRemarkMailTomsResponse, GetRemarkMailTomsRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/remarks/mails/toms`,
        method: 'GET',
        params,
      }),
      providesTags: ['RemarkMailToms'],
    }),
    getRemarkResponsible: build.query<GetRemarkResponsibleResponse, GetRemarkResponsibleRequest>({
      query: ({ id, ...params }) => ({
        url: `/project/${id}/remarks/mails/responsible`,
        method: 'GET',
        params,
      }),
    }),
    manualSendRemark: build.mutation<ManualSendRemarkResponse, ManualSendRemarkRequest>({
      query: ({ id, type, file, localDateTime, ...body }) => {
        const formData = new FormData()
        Object.entries(body).forEach(([key, value]) => {
          formData.append(key, JSON.stringify(value))
        })
        formData.append('localDateTime', localDateTime)
        if (file) {
          formData.append('file', file)
        }
        return {
          url: `/project/${id}/remarks/mails/send`,
          method: 'POST',
          params: { type },
          body: formData,
        }
      },
      invalidatesTags: ['Remarks', 'RemarkMailToms', 'RemarkAmount', 'RemarkWidget', 'RemarkOnId', 'RemarkGroups'],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTomRemarksQuery,
  useGetProjectRemarksQuery,
  useGetProjectGroupedRemarksQuery,
  useGetRemarkOnIdQuery,
  useGetRemarksWidgetQuery,
  useGetAmountRemarksQuery,
  useRemarkAccessQuery,
  useGetResponsibleListQuery,
  useGetRemarkTypesListQuery,
  useGetRemarkFilterGroupsQuery,
  useGetProjectTomsQuery,
  useCreateRemarkMutation,
  useEditRemarkMutation,
  useAddRemarkMessageMutation,
  useDownloadRemarkAttachmentMutation,
  useDeleteRemarkMutation,
  useLazyExportRemarksQuery,
  useLazyDownloadRemarkMailQuery,
  useLazyDownloadRemarkMailAttachmentQuery,
  useGetRemarkMailTomsQuery,
  useGetRemarkResponsibleQuery,
  useManualSendRemarkMutation,
} = remarkApi
