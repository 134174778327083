import { Button } from "@mui/material";
import styled from "styled-components";

export const RemarkMenuButton = styled(Button)`
  border-color: ${props => props.theme.palette.primaryBorderLight};
  background-color: ${props => props.theme.palette.bg.lightBlue};
  padding: 8px 8px 6px 16px;
  max-width: 100%;
  justify-content: space-between;
  min-width: 266px;

  :hover {
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`