import { HeadBtnState, RemarkStatus } from '../../../../../../api/remarks/types'
import { RemarkGroupedTableMenuTitle, RemarkMenuTitle, RemarkStatusRu, RemarkTableMenuTitle, remarkStatusEnToRu } from './Remarks.types'

export const MAX_FILENAME_LENGTH = 30
export const MAX_HEIGHT_REMARK = 700

export interface AvailableStatuses extends Record<RemarkStatus, typeof userRolesArray> { }
export interface RemarkMenuList extends Record<RemarkStatus, Array<RemarkMenuTitle>> { }
export interface RemarkTableMenuList extends Partial<Record<RemarkStatus, Array<RemarkTableMenuTitle>>> { }
export interface RemarkGroupedTableMenuList extends Partial<Record<RemarkStatus, Array<RemarkGroupedTableMenuTitle | undefined>>> { }
export const userRolesArray = Object.values(remarkStatusEnToRu) as Array<RemarkStatusRu>
type RemarkMembers = 'responsible' | 'author' | 'admin'

// Ответственная компания
const availableStatusesForResponsible: Partial<AvailableStatuses> = {
  submitted: ['В работе', 'Отработано'],
  accepted: ['Отработано'],
}
// Автор
const availableStatusesForAuthor: Partial<AvailableStatuses> = {
  submitted: [],
  accepted: [],
  processed: ['Создано', 'Закрыто'],
}
// Администратор
const availableStatusesForAdmin: Partial<AvailableStatuses> = {
  submitted: ['В работе', 'Отработано'],
  accepted: ['Отработано'],
  processed: ['Создано', 'Закрыто'],
}

export const availableStatuses: Record<RemarkMembers, Partial<AvailableStatuses>> = {
  responsible: availableStatusesForResponsible,
  author: availableStatusesForAuthor,
  admin: availableStatusesForAdmin,
}

export const titleStatus: Record<string, string> = {
  'В работе': 'Принять в работу',
  Закрыто: 'Закрыто',
  Отклонено: 'Отклонено',
  Снято: 'Снять замечание',
  Создано: 'Создать повторно',
  Устранено: 'Устранено',
  'Редактировать замечание': 'Редактировать замечание',
  'Удалить замечание': 'Удалить замечание',
}

export const remarkMenuList = (isAdmin: boolean, isSecond: boolean): RemarkMenuList => ({
  submitted: isAdmin ? ['Удалить замечание'] : [],
  accepted: isAdmin ? ['Удалить замечание'] : [],
  processed: isAdmin ? ['Удалить замечание'] : [],
  closed: isAdmin ? ['Удалить замечание'] : [],
  created: isSecond ? ['Удалить замечание'] : ['Редактировать замечание', 'Удалить замечание'],
})

export const remarkTableMenuList = (isAdmin: boolean, isSecond: boolean, remarkSelector: "incoming" | "outgoing"): RemarkTableMenuList => {
  if (remarkSelector === "incoming") {
    return {
      submitted: isAdmin ? ['Удалить замечание', 'Назначить исполнителя'] : ['Назначить исполнителя'],
      accepted: isAdmin ? ['Удалить замечание', 'Назначить исполнителя'] : ['Назначить исполнителя'],
      processed: isAdmin ? ['Удалить замечание'] : [],
      closed: isAdmin ? ['Удалить замечание'] : []
    }
  }
  return {
    submitted: isAdmin ? ['Удалить замечание'] : [],
    accepted: isAdmin ? ['Удалить замечание'] : [],
    processed: isAdmin ? ['Удалить замечание'] : [],
    closed: isAdmin ? ['Удалить замечание'] : [],
    created: isSecond ? ['Удалить замечание'] : ['Редактировать замечание', 'Удалить замечание'],
  }
}

export const remarkGroupedTableMenuList = (isAdmin: boolean, remarkSelector: "incoming" | "outgoing", btnState: HeadBtnState, responsible: boolean): RemarkGroupedTableMenuList => {
  const { attachments, executors } = btnState

  const executorsMenuItem: Record<('EMPTY' | 'EXISTS'), RemarkGroupedTableMenuTitle> = {
    'EMPTY': 'Назначить исполнителя',
    'EXISTS': 'Сменить исполнителя',
  }

  const attachmentsMenuItem: Record<('SINGLE' | 'MANY' | 'NONE'), RemarkGroupedTableMenuTitle | undefined> = {
    'SINGLE': 'Скачать вложение',
    'MANY': 'Скачать вложения',
    'NONE': undefined
  }

  if (remarkSelector === "incoming") {
    return {
      submitted: isAdmin
        ? [executorsMenuItem[executors], responsible ? 'Сменить ответственного' : 'Назначить ответственного', attachmentsMenuItem[attachments]]
        : [executorsMenuItem[executors], attachmentsMenuItem[attachments]],
      accepted: isAdmin
        ? [executorsMenuItem[executors], responsible ? 'Сменить ответственного' : 'Назначить ответственного', attachmentsMenuItem[attachments]]
        : [executorsMenuItem[executors], attachmentsMenuItem[attachments]],
      processed: [attachmentsMenuItem[attachments]],
      closed: [attachmentsMenuItem[attachments]]
    }
  }
  return {
    submitted: isAdmin
      ? [responsible ? 'Сменить ответственного' : 'Назначить ответственного', attachmentsMenuItem[attachments]]
      : [attachmentsMenuItem[attachments]],
    accepted: isAdmin
      ? [responsible ? 'Сменить ответственного' : 'Назначить ответственного', attachmentsMenuItem[attachments]]
      : [attachmentsMenuItem[attachments]],
    processed: [attachmentsMenuItem[attachments]],
    closed: [attachmentsMenuItem[attachments]],
    created: [attachmentsMenuItem[attachments]],
  }
}