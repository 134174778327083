import React, { ChangeEvent, FC, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import useSearch from '../../hooks/useSearch'
import { useNavigate, useParams } from 'react-router-dom'
import { useBreadcrumbMenu } from '../../hooks/useBreadcrumbMenu'
import useBreadcrumbs from '../../hooks/useBreadcrumbs'
import { useGetProjectByIdQuery } from '../../api/projects'
import Header from '../../components/Header'
import { InnerContainer, MainContainer } from '../../layouts/HomeLayout/styles'
import { Box, Grid, Stack } from '@mui/material'
import {
  useGetAgreementPocketQuery,
  useGetAgreementPocketStagesQuery,
  useGetAgreementPocketTomsQuery
} from '../../api/agreementPocket'
import { PocketViewNavbar } from './components/PocketViewNavbar'
import { AgreementPocketTom, AgreementPocketTomTabStatus } from '../../api/agreementPocket/types'
import { SelectItem } from '../../components/FilterSelectMulti/FilterSelectMultiple.types'
import { PocketViewTomCard } from './components/PocketViewTomCard'
import Progress from '../../components/Progress'
import EmptyPage from '../../components/EmptyPage'
import { EmptyPageData } from '../../components/EmptyPage/EmptyPage.types'
import { getEmptyPageData } from '../Home'
import { filterByFieldNames } from '../../utils/filterByFieldNames'
import { useAppDispatch, useTypedSelector } from '../../store/store'
import { PagesViewMode, pocketTomsViewSelector, setPageViewMode, ViewMode } from '../../store/slices/ui'
import { AgGridPocketToms } from './components/AgGridPocketToms'
import { ViewModeButtonGroup } from '../../components/ViewModeButtonGroup'

export const PocketView: FC = () => {
  const { searchValue } = useSearch()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { projectId: projectIdString, pocketId: pocketIdString } = useParams()
  const projectId = Number(projectIdString)
  const pocketId = Number(pocketIdString)
  const viewMode = useTypedSelector(pocketTomsViewSelector)
  const [statusTabValue, setStatusTabValue] = useState<AgreementPocketTomTabStatus>('all')
  const [filterStageIds, setFilterStageIds] = useState<number[]>([])
  const [filterExpired, setFilterExpired] = useState<boolean>(false)

  const onStageClick = (stageId: number | null) => {
    (filterStageIds.length === 1 && filterStageIds.includes(stageId!))
      || !stageId
      ? setFilterStageIds([])
      : setFilterStageIds([stageId])

    setStatusTabValue('all')
    // setFilterExpired(false)
  }

  const onViewChange = useCallback((updatedViewMode: ViewMode) => {
    if (updatedViewMode !== null) {
      dispatch(setPageViewMode({ page: 'pocketToms', viewMode: updatedViewMode }))
    }
  }, [])

  const onStatusTabChange = (e: SyntheticEvent, tabStatus: AgreementPocketTomTabStatus) => {
    tabStatus !== statusTabValue && setStatusTabValue(tabStatus)

    setFilterStageIds([])
  }

  const onFilterStageIdsChange = (selectedIds: number[]) => {
    setFilterStageIds(selectedIds)

    setStatusTabValue('all')
  }

  const onFilterExpiredChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFilterExpired(e.target.checked)
  }

  const { data: projectData, isFetching: isProjectFetching } = useGetProjectByIdQuery({ id: projectId })
  const { data: project } = projectData || {}

  const { data: pocket, isFetching: isPocketFetching } = useGetAgreementPocketQuery({ projectId, pocketId })

  const { data: stagesData } = useGetAgreementPocketStagesQuery({ projectId, pocketId })
  const { data: stages = [], completeTomCount = 0 } = stagesData || {}

  const { data: tomsData, isLoading: isTomsLoading, isFetching: isTomsFetching, refetch: refetchToms } = useGetAgreementPocketTomsQuery({
    projectId,
    pocketId,
    expired: filterExpired || undefined,
    stageIds: filterStageIds.length ? filterStageIds : undefined
  })
  const { data: toms = [], existData: tomsExist } = tomsData || {}

  useEffect(() => {
    refetchToms()
    // dispatch(
    //   agreementPocketApi.util.invalidateTags([{ type: 'AgreementPocket', id: 'AGREEMENT-TOMS-LIST' }])
    // )
  }, [filterStageIds])

  const stageFilterItems: SelectItem<number>[] = stages.map(stage => ({
    value: stage.id,
    title: stage.title,
  }))

  const onProjectItemClick = (projectId: number) => {
    navigate(`/project/${projectId}/pockets`)
  }

  const { onProjectBreadClick, isProjectsMenuOpened, ProjectMenu } = useBreadcrumbMenu({ onProjectItemClick })
  useBreadcrumbs([
    {
      title: project?.shortName || '',
      onClick: (e) => onProjectBreadClick(e),
      MenuOpen: isProjectsMenuOpened,
      type: 'project'
    },
    { title: 'Реестр передачи документации' },
    { title: `Накладная № ${pocket?.title}` || '' },
  ],
    [isProjectsMenuOpened, project, pocket],
    isProjectFetching || isPocketFetching
  )

  const getFilteredData = (data: AgreementPocketTom[]) => {
    const filteredByStatus = statusTabValue === 'all' ? data : data.filter(item => item.status === statusTabValue)
    const filteredBySearch = searchValue
      ? filterByFieldNames<AgreementPocketTom>(
        filteredByStatus,
        ['tom.title' as keyof AgreementPocketTom, 'tom.description' as keyof AgreementPocketTom],
        searchValue)
      : filteredByStatus

    return filteredBySearch
  }

  const filteredData = getFilteredData(toms)

  const emptyPageData: EmptyPageData = getEmptyPageData(
    <>Нет томов для отображения.</>,
  )

  const emptyFilteredData: EmptyPageData = getEmptyPageData(
    <>Отсутствуют тома, соответствующие результатам запроса.</>
  )

  useEffect(() => {
    const cachedPagesViewMode: PagesViewMode = JSON.parse(localStorage.getItem('pagesViewMode') || '{}')
    if (cachedPagesViewMode?.pocketToms) {
      dispatch(setPageViewMode({ page: 'pocketToms', viewMode: cachedPagesViewMode.pocketToms }))
    }
  }, [])

  return (
    <>
      <PocketViewNavbar
        stages={stages}
        filterStageIds={filterStageIds}
        filterExpired={filterExpired}
        onStageClick={onStageClick}
        statusTabValue={statusTabValue}
        onStatusTabChange={onStatusTabChange}
        onFilterExpiredChange={onFilterExpiredChange}
        agreedTomsCount={completeTomCount}
      />
      <MainContainer>
        <Header />
        <InnerContainer>
          <Stack flex={1} width='100%'>
            {isTomsLoading
              ? <Progress />
              : tomsExist
                ? (
                  <>
                    <Box alignSelf='flex-end' pt={1}>
                      <ViewModeButtonGroup viewMode={viewMode} onViewChange={onViewChange} />
                    </Box>
                    {isTomsFetching
                      ? <Progress />
                      : filteredData.length
                        ?
                        viewMode === 'list' ? (
                          <Grid sx={{ py: 1.25 }} spacing={2.5} container>
                            {filteredData.map(tomData => (
                              <Grid
                                item
                                xs={12} md={6} lg={4} xl={3} xxl={2.4} xxxl={2}
                                container
                                justifyContent='center'
                                key={tomData.id}
                              >
                                <PocketViewTomCard tomData={tomData} />
                              </Grid>
                            ))}
                          </Grid>
                        ) : (
                          <AgGridPocketToms pocketToms={filteredData} isAwaiting={!pocket?.dateReceive} />
                        )
                        : <EmptyPage data={emptyFilteredData} forFilter />
                    }
                  </>
                )
                : <EmptyPage data={emptyPageData} fullPage />
            }
          </Stack>
        </InnerContainer>
      </MainContainer>
      {ProjectMenu}
    </>
  )
}
