import { Box, Stack, Typography } from '@mui/material'
import {
  CellClickedEvent,
  ColDef,
  ColumnResizedEvent,
  ColumnState,
  GetRowIdParams,
  GridReadyEvent,
  ICellRendererParams,
  IsFullWidthRowParams,
  RowClassParams,
  RowDataUpdatedEvent,
  RowHeightParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useSnackbar } from 'notistack'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowUp'
import { useDeleteRemarkMutation } from '../../../../api/remarks'
import { RemarkInfo, RemarkLinkedTom, RemarkMessage, RemarkProjectItem, RemarkRowInfo, RemarkRowMessage } from '../../../../api/remarks/types'
import { OverflowText } from '../../../../components/OverflowText/OverflowText'
import Progress from '../../../../components/Progress'
import useConfirmDialog, { UseExitConfirmProps } from '../../../../hooks/useConfirmDialog'
import { setOpenedDrawer } from '../../../../store/slices/documentsPages/drawerInfo'
import { profileSelector } from '../../../../store/slices/profile'
import { setAllowScrolling, setRemarkFormData, setRemarkFormMode } from '../../../../store/slices/remarks/remarks'
import { RemarkFormData } from '../../../../store/slices/remarks/remarks.types'
import { allowScrollingSelector } from '../../../../store/slices/remarks/selectors/remarks.selectors'
import { useAppDispatch, useTypedSelector } from '../../../../store/store'
import { StyledAgGridWrapper } from '../../../../styles/global/StyledAgGridWrapper'
import { convertDateTime } from '../../../../utils/formatDateAndTimeGMT'
import {
  RemarkGroupedTableMenuTitle,
  RemarkMenuTitle,
  RemarkTableMenuTitle,
  StatusLabel,
  remarkColorStatus,
  remarkStatusEnToRu,
  remarkStatusRuToEn,
} from '../../../DocView/components/DocViewRightSideBar/components/Remark'
import {
  remarkGroupedTableMenuList,
  remarkMenuList,
  remarkTableMenuList,
  titleStatus,
} from '../../../DocView/components/DocViewRightSideBar/components/Remark/Remark.config'
import { RemarkMenu } from '../../../DocView/components/DocViewRightSideBar/components/RemarkMenu'
import { ArrowComponent, ElipsisText, InfoText, StyledHeadCell, StyledLabel } from './RemarksTable.styles'
import { AnswerParams, HeadRowParams, MenuParams, RemarkCollapse, RemarkParams, RemarkTableProps, RowsData, StatusParams, TomInfoParams } from './RemarksTable.types'

import 'ag-grid-community/styles/ag-theme-alpine.css'
import { RemarkTableMenu } from './components/RemarkTableMenu'

const DEFAULT_ROW_HEIGHT = 110

const HeadCell = (params: ICellRendererParams<RowsData>, collapseMail: (mailId: number) => void, cWidth?: number, mWidth?: number) => {
  if (!params.data?.head || !params.data.menu) return
  const { mailDate, mailNum, responsible, responsibleCompany, close, id }: HeadRowParams = params.data.head
  const { isShow, remarkMenu }: MenuParams = params.data.menu
  return (
    <StyledHeadCell direction='row' onClick={() => collapseMail(id)}>
      <Box width={cWidth}>
        <ArrowComponent arrowClose={close}>
          <KeyboardArrowDownIcon fontSize='large' />
        </ArrowComponent>
      </Box>
      <Stack direction='row' alignItems='center' spacing={1} pl={1.5}>
        <Typography variant='body2' fontWeight={500}>{`Письмо № ${mailNum} от ${mailDate}`}</Typography>
        <Typography variant='body2' fontSize={12}>Ответственный:</Typography>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <path
            d='M13.9998 12.6667H15.3332V14H0.666504V12.6667H1.99984V2.66667C1.99984 2.48986 2.07008 2.32029 2.1951 2.19526C2.32012 2.07024 2.48969 2 2.6665 2H9.33317C9.50998 2 9.67955 2.07024 9.80457 2.19526C9.9296 2.32029 9.99984 2.48986 9.99984 2.66667V12.6667H11.3332V6H13.3332C13.51 6 13.6796 6.07024 13.8046 6.19526C13.9296 6.32029 13.9998 6.48986 13.9998 6.66667V12.6667ZM4.6665 7.33333V8.66667H7.33317V7.33333H4.6665ZM4.6665 4.66667V6H7.33317V4.66667H4.6665Z'
            fill='#5C6E8C'
          />
        </svg>
        <InfoText>{responsibleCompany}</InfoText>

        {responsible && (
          <>
            <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M11.3013 4.51531C11.3013 6.27258 9.891 7.69712 8.1513 7.69712C6.4116 7.69712 5.0013 6.27258 5.0013 4.51531C5.0013 2.75804 6.4116 1.3335 8.1513 1.3335C9.891 1.3335 11.3013 2.75804 11.3013 4.51531ZM8 9.51532C11.3265 9.51532 14 10.9706 14 12.7608V14.6668H2V12.7608C2 10.9706 4.67346 9.51532 8 9.51532Z'
                fill='#5C6E8C'
              />
            </svg>
            <InfoText>{responsible}</InfoText>
          </>)
        }
      </Stack>
      <Box height='100%' display='flex' alignItems='center' justifyContent='center' ml='auto' width={mWidth}>
        <RemarkTableMenu data={remarkMenu} disabled={!isShow} />
      </Box>
    </StyledHeadCell>
  )
}

const AuthorInfo = (company: string | null, person: string, createdAt: string) => {

  return (
    <Stack direction='row' spacing={2} flex={1} alignItems='flex-end'>
      <Box display='inline-flex' maxWidth='calc(33% - 16px)'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <path
            d='M13.9998 12.6667H15.3332V14H0.666504V12.6667H1.99984V2.66667C1.99984 2.48986 2.07008 2.32029 2.1951 2.19526C2.32012 2.07024 2.48969 2 2.6665 2H9.33317C9.50998 2 9.67955 2.07024 9.80457 2.19526C9.9296 2.32029 9.99984 2.48986 9.99984 2.66667V12.6667H11.3332V6H13.3332C13.51 6 13.6796 6.07024 13.8046 6.19526C13.9296 6.32029 13.9998 6.48986 13.9998 6.66667V12.6667ZM4.6665 7.33333V8.66667H7.33317V7.33333H4.6665ZM4.6665 4.66667V6H7.33317V4.66667H4.6665Z'
            fill='#5C6E8C'
          />
        </svg>
        <InfoText ml={1}>{company}</InfoText>
      </Box>
      <Box display='inline-flex' maxWidth='calc(33% - 16px)'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M11.3013 4.51531C11.3013 6.27258 9.891 7.69712 8.1513 7.69712C6.4116 7.69712 5.0013 6.27258 5.0013 4.51531C5.0013 2.75804 6.4116 1.3335 8.1513 1.3335C9.891 1.3335 11.3013 2.75804 11.3013 4.51531ZM8 9.51532C11.3265 9.51532 14 10.9706 14 12.7608V14.6668H2V12.7608C2 10.9706 4.67346 9.51532 8 9.51532Z'
            fill='#5C6E8C'
          />
        </svg>
        <InfoText ml={1}>{person}</InfoText>
      </Box>
      <Box display='inline-flex' maxWidth='calc(33% - 16px)'>
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'>
          <path
            d='M6 6.6665V7.99984H4.66667V6.6665H6ZM8.66667 6.6665V7.99984H7.33333V6.6665H8.66667ZM11.3333 6.6665V7.99984H10V6.6665H11.3333ZM12.6667 1.99984C13.0203 1.99984 13.3594 2.14031 13.6095 2.39036C13.8595 2.64041 14 2.97955 14 3.33317V12.6665C14 13.0201 13.8595 13.3593 13.6095 13.6093C13.3594 13.8594 13.0203 13.9998 12.6667 13.9998H3.33333C2.97971 13.9998 2.64057 13.8594 2.39052 13.6093C2.14048 13.3593 2 13.0201 2 12.6665V3.33317C2 2.97955 2.14048 2.64041 2.39052 2.39036C2.64057 2.14031 2.97971 1.99984 3.33333 1.99984H4V0.666504H5.33333V1.99984H10.6667V0.666504H12V1.99984H12.6667ZM12.6667 12.6665V5.33317H3.33333V12.6665H12.6667ZM6 9.33317V10.6665H4.66667V9.33317H6ZM8.66667 9.33317V10.6665H7.33333V9.33317H8.66667ZM11.3333 9.33317V10.6665H10V9.33317H11.3333Z'
            fill='#5C6E8C'
          />
        </svg>
        {<InfoText ml={1}>{convertDateTime(createdAt, false, true)}</InfoText>}
      </Box>
    </Stack>
  )
}

const CollapseCell = (id: number, close: boolean, collapse: (id: number) => void) => {
  return (
    <ArrowComponent
      arrowClose={close}
      ref={(ref: any) => {
        if (!ref) return
        ref.onclick = (e: any) => {
          e.stopPropagation()
          collapse(id)
        }
      }}
    >
      <KeyboardArrowDownIcon fontSize='large' />
    </ArrowComponent>
  )
}

const TomInfoCell = (params: ICellRendererParams) => {
  const { shifr, list, version, change }: TomInfoParams = params.value
  return (
    <Box px={2} textAlign='start' width='inherit'>
      <ElipsisText fontWeight={500} fontSize={14}>{`Том: ${shifr}`}</ElipsisText>
      <Typography fontSize={12}>{`Версия: ${version !== null ? version : '—'}`}</Typography>
      {change && <Typography fontSize={12}>{`Изменение: ${change}`}</Typography>}
      <Typography fontSize={12}>{`Лист: ${list || '—'}`}</Typography>
    </Box>
  )
}

const ReplyCell = (params: ICellRendererParams) => {
  const { text, company, person, createdAt }: AnswerParams = params.value || {}
  if (!params.value)
    return (
      <Stack width='100%' alignItems='center' justifyContent='center'>
        <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
          <path d='M13.1668 8.66683H3.8335V7.3335H13.1668V8.66683Z' fill='#5C6E8C' />
        </svg>
      </Stack>
    )
  return (
    <Stack px={2} py={1} width='100%'>
      <OverflowText mode='table' text={text} defaultHeight={42} marginTop={3} marginBottom={1} />
      {AuthorInfo(company, person, createdAt)}
    </Stack>
  )
}

const ReasonCell = (params: ICellRendererParams) => {
  return (
    <Stack px={2} py={1} width='100%'>
      <OverflowText mode='table' text={params.value || '—'} defaultHeight={80} />
    </Stack>
  )
}

const StatusCell = (params: ICellRendererParams) => {
  const { remarkStatus, isSecond }: StatusParams = params.value || {}
  return (
    <Stack direction='column' spacing={1} alignItems='center'>
      {isSecond && (
        <StyledLabel>
          <Typography variant='body2' fontSize={12} fontWeight={500}>
            Повторное
          </Typography>
        </StyledLabel>
      )}
      <StyledLabel direction='row' spacing={1}>
        <StatusLabel color={remarkColorStatus[remarkStatus]} />
        <Typography fontSize={12} fontWeight={500}>
          {remarkStatusEnToRu[remarkStatus]}
        </Typography>
      </StyledLabel>
    </Stack>
  )
}

const RemarkCell = (params: ICellRendererParams) => {
  const { text, company, person, createdAt }: RemarkParams = params.value || {}
  return (
    <Stack px={2} py={1} width='100%'>
      <OverflowText mode='table' text={text} defaultHeight={42} marginBottom={1} />
      {AuthorInfo(company, person, createdAt)}
    </Stack>
  )
}

const MenuButton = (params: ICellRendererParams) => {
  const { remarkMenu, isShow }: MenuParams = params.value
  return (
    <RemarkTableMenu data={remarkMenu} disabled={!isShow} />
  )
}

const flexStartClass = 'ag-cell_flex_column ag-cell_justify-content_center ag-cell_align-items_start'
const flexCenterClass = 'ag-cell_flex_column ag-cell_justify-content_center ag-cell_align-items_center'

export const RemarksTable = ({ remarks, remarkSelector, currentTab }: RemarkTableProps) => {
  const { projectId: projectIdString } = useParams()
  const projectId = Number(projectIdString)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const profile = useTypedSelector(profileSelector)
  const isAdmin = profile.role === 'admin'
  const gridRef = useRef<AgGridReact<RowsData> | null>(null)
  const { allowScrolling, mode } = useTypedSelector(allowScrollingSelector)
  const savedColumnState = localStorage.getItem('remarkColumnState')
  const isSavedColumnState = !!savedColumnState
  const selectedStatus = remarkStatusRuToEn[currentTab]
  const [remarkGroupedIds, setRemarkGroupedIds] = useState<number[]>([])
  const [remarkMailIds, setRemarkMailIds] = useState<number[]>([])

  const rowClassRules = {
    'ag-row_style_shades': (params: RowClassParams<RowsData>) => {
      return !params.data?.isNested
    },
  }

  const [deleteRemark, deleteRemarkResponse] = useDeleteRemarkMutation()

  const confirmDeleteRemark = (confirm: boolean, id: number) => {
    if (confirm) {
      deleteRemark({ projectId, remarkId: id })
    }
  }

  const collapse = useCallback((tomId: number) => {
    setRemarkGroupedIds((idsArray) => {
      if (idsArray.includes(tomId)) {
        return idsArray.filter((id) => id !== tomId)
      } else {
        return [...idsArray, tomId]
      }
    })
  }, [])

  const collapseMail = useCallback((mailId: number) => {
    setRemarkMailIds((idsArray) => {
      if (idsArray.includes(mailId)) {
        return idsArray.filter((id) => id !== mailId)
      } else {
        return [...idsArray, mailId]
      }
    })
  }, [])

  const dataForConfirmDialog: UseExitConfirmProps = {
    handleConfirm: confirmDeleteRemark,
    body: <>Замечание будет удалено без возможности восстановить</>,
    title: 'Удалить замечание?',
    denyButtonText: 'Отменить',
    confirmButtonText: 'Удалить',
    confirmButtonColor: 'error',
  }

  const { ConfirmDialog, openConfirm } = useConfirmDialog(dataForConfirmDialog)

  const onEditRemark = (remarkId: number, message: RemarkRowMessage, tom: RemarkLinkedTom) => {
    dispatch(
      setRemarkFormData({
        remarkFormData: {
          change: tom.change,
          page: tom.page,
          totalPages: null,
          comment: message.comment,
          reason: message.reason,
          type: tom.type,
          version: tom.version,
          remarkId,
        } as RemarkFormData,
      }),
    )
    dispatch(setRemarkFormMode({ remarkFormMode: 'edit' }))
    dispatch(setOpenedDrawer({ openedDrawer: 'remarks' }))
  }

  const executeMenuFunction = useCallback(
    (status: RemarkTableMenuTitle, remarkId: number, message: RemarkRowMessage, tom: RemarkLinkedTom) => {
      let callback: ((value?: string) => void) | undefined

      switch (status) {
        case 'Удалить замечание':
          callback = () => openConfirm(remarkId)
          break
        case 'Редактировать замечание':
          callback = () => onEditRemark(remarkId, message, tom)
          break
        case 'Назначить исполнителя':
          callback = (value?: string) => console.log('Назначить исполнителя', value)
          break
        case 'Сменить исполнителя':
          callback = (value?: string) => console.log('Сменить исполнителя', value)
          break
      }
      return callback
    },
    [remarks],
  )

  const executeGroupedMenuFunction = useCallback(
    (status: RemarkGroupedTableMenuTitle) => {
      let callback: ((value?: string) => void) | undefined

      switch (status) {
        case 'Назначить исполнителя':
          callback = (value?: string) => console.log('Назначить исполнителя', value)
          break
        case 'Назначить ответственного':
          callback = (value?: string) => console.log('Назначить ответственного', value)
          break
        case 'Скачать вложения':
          callback = () => console.log('Скачать вложения')
          break
          case 'Скачать вложение':
            callback = () => console.log('Скачать вложения')
            break
        case 'Сменить исполнителя':
          callback = (value?: string) => console.log('Сменить исполнителя', value)
          break
        case 'Сменить ответственного':
          callback = (value?: string) => console.log('Сменить ответственного', value)
          break
      }
      return callback
    },
    [remarks],
  )

  const isFullWidthRow = useCallback((params: IsFullWidthRowParams<RowsData>) => {
    return params.rowNode.data?.head !== null
  }, []);
  const fullWidthCellRenderer = useCallback((params: ICellRendererParams<RowsData>) => {
    const cWidth = savedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'collapse')?.width : 30
    const mWidth = savedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'menu')?.width : 40
    return HeadCell(params, collapseMail, cWidth, mWidth)
  }, [savedColumnState])

  const columnDef = useMemo((): ColDef[] => ([
    {
      field: 'collapse',
      headerName: '',
      flex: !savedColumnState ? 1 : undefined,
      width: savedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'collapse')?.width : 30,
      minWidth: 30,
      cellClass: [flexCenterClass],
      suppressMovable: true,
      lockPosition: true,
      cellRenderer: (params: ICellRendererParams<RowsData, RemarkCollapse>) =>
        params.value?.isSecond ? CollapseCell(params.value.id!, params.value.close!, collapse) : null,
    },
    {
      field: 'index',
      headerName: '№',
      flex: !isSavedColumnState ? 1 : undefined,
      width: isSavedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'index')?.width : 56,
      minWidth: 56,
      cellClass: [flexCenterClass, 'font-size_12'],
      sortable: /* true */false,
      /* sortingOrder: ['asc', 'desc', null], */
    },
    {
      field: 'tomName',
      headerName: 'О томе',
      flex: !isSavedColumnState ? 3 : undefined,
      width: isSavedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'tomName')?.width : 145,
      minWidth: 145,
      cellRenderer: TomInfoCell,
      cellClass: [flexStartClass],
    },
    {
      field: 'remark',
      headerName: 'Замечание',
      flex: !isSavedColumnState ? 10 : undefined,
      width: isSavedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'remark')?.width : 322,
      minWidth: 322,
      cellRenderer: RemarkCell,
      cellClass: [flexStartClass, 'ag-cell_overflow_visible'],
    },
    {
      field: 'reason',
      headerName: 'Основание',
      flex: !isSavedColumnState ? 7 : undefined,
      width: isSavedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'reason')?.width : 200,
      minWidth: 200,
      cellRenderer: ReasonCell,
      cellClass: [flexStartClass, 'ag-cell_overflow_visible'],
    },
    {
      field: 'status',
      headerName: 'Статус',
      flex: !isSavedColumnState ? 2 : undefined,
      width: isSavedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'status')?.width : 130,
      minWidth: 130,
      cellRenderer: StatusCell,
      cellClass: [flexCenterClass, 'ag-cell_overflow_visible'],
    },
    {
      field: 'answer',
      headerName: 'Ответ',
      flex: !isSavedColumnState ? 8 : undefined,
      width: isSavedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'answer')?.width : 300,
      minWidth: 300,
      cellRenderer: ReplyCell,
      cellClass: [flexStartClass, 'ag-cell_overflow_visible'],
    },
    {
      field: 'menu',
      headerName: '',
      flex: !isSavedColumnState ? 2 : undefined,
      width: isSavedColumnState ? JSON.parse(savedColumnState).find((s: ColumnState) => s.colId === 'menu')?.width : 40,
      minWidth: 40,
      cellRenderer: MenuButton,
      cellClass: [flexCenterClass],
    },
  ]), [collapse, isSavedColumnState, savedColumnState])

  const rowData = useMemo((): RowsData[] => {
    if (!remarks) return []

    const parseRowData = (rows: RemarkProjectItem[]): RowsData[] => {
      const data: RowsData[] = rows.flatMap(({ row, secondRows }, index) => {
        const isAuthor = profile.employeeId === row.question.author.id

        const mainRow: RowsData = {
          head: null,
          collapse: {
            id: row.remark.id,
            isSecond: !!secondRows,
            close: remarkGroupedIds.includes(row.remark.id)
          },
          index: index + 1,
          tomName: {
            id: row.tom.id,
            shifr: row.tom.title,
            version: row.tom.version,
            change: row.tom.change,
            list: row.tom.page,
          },
          remark: {
            remarkId: row.remark.id,
            text: row.question.comment,
            company: row.question.author.company,
            person: row.question.author.name,
            createdAt: row.question.createdAt,
          },
          reason: row.question.reason,
          status: {
            remarkStatus: row.remark.status,
            isSecond: row.remark.isSecond
          },
          answer: row.answer
            ? {
              text: row.answer.comment,
              company: row.answer.author.company,
              person: row.answer.author.name,
              createdAt: row.answer.createdAt,
            }
            : undefined,
          menu: {
            remarkMenu: remarkTableMenuList(isAdmin, row.remark.isSecond, remarkSelector)[row.remark.status]?.map((item) => ({
              itemName: item,
              action: executeMenuFunction(item, row.remark.id, row.question, row.tom),
            })),
            isShow: isAdmin || isAuthor,
          },
        }

        if (!!secondRows && remarkGroupedIds.includes(row.remark.id)) {
          const groupedRows: RowsData[] = secondRows.map((groupedRow, groupedIndex) => ({
            head: null,
            collapse: {
              isSecond: false,
            },
            index: `${index + 1}.${groupedIndex + 1}`,
            tomName: {
              id: groupedRow.tom.id,
              shifr: groupedRow.tom.title,
              version: groupedRow.tom.version,
              change: groupedRow.tom.change,
              list: groupedRow.tom.page,
            },
            remark: {
              remarkId: groupedRow.remark.id,
              text: groupedRow.question.comment,
              company: groupedRow.question.author.company,
              person: groupedRow.question.author.name,
              createdAt: groupedRow.question.createdAt,
            },
            reason: groupedRow.question.reason,
            status: {
              remarkStatus: groupedRow.remark.status,
              isSecond: groupedRow.remark.isSecond
            },
            answer: groupedRow.answer
              ? {
                text: groupedRow.answer.comment,
                company: groupedRow.answer.author.company,
                person: groupedRow.answer.author.name,
                createdAt: groupedRow.answer.createdAt,
              }
              : undefined,
            menu: {
              remarkMenu: remarkTableMenuList(isAdmin, groupedRow.remark.isSecond, remarkSelector)[groupedRow.remark.status]?.map((item) => ({
                itemName: item,
                action: executeMenuFunction(item, groupedRow.remark.id, groupedRow.question, groupedRow.tom),
              })),
              isShow: isAdmin || isAuthor,
            },
            isNested: true
          }))
          return [mainRow, ...groupedRows]
        }
        return [mainRow]
      })
      return data
    }

    const data = remarks.flatMap(({ head, rows }): RowsData[] => {
      if (head !== null) {
        const headRow: RowsData = {
          head: {
            id: head.mail.id,
            close: remarkMailIds.includes(head.mail.id),
            mailDate: head.mail.mailDate,
            mailNum: head.mail.mailNum,
            responsible: head.mail.responsible?.name || null,
            responsibleCompany: head.mail.responsibleCompany
          },
          menu: {
            remarkMenu: remarkGroupedTableMenuList(isAdmin, remarkSelector, head.btnState, !!head.mail.responsible)
            [selectedStatus]?.filter(i => !!i).map((item) => ({
              itemName: item!,
              action: executeGroupedMenuFunction(item!),
            })),
            isShow: true,
          },
        }
        if (!remarkMailIds.includes(head.mail.id)) {
          const mailRowsData = parseRowData(rows)
          return [headRow, ...mailRowsData]
        } else return [headRow]
      } else return parseRowData(rows)
    })

    return data
  }, [remarks, remarkGroupedIds, remarkMailIds, remarkSelector])

  console.log(rowData)

  const getRowHeight = useCallback((params: RowHeightParams) => {
    if (params.data.head !== null) {
      return 50
    }
  }, [])

  const getRowId = useCallback((params: GetRowIdParams<RowsData>) =>
    params.data.head ? `${params.data.head.id}` : `${params.data.remark?.remarkId}-${params.data.index}`, [])

  const cellClickedListener = (e: CellClickedEvent) => {
    const notAllowedCell = e.colDef.field === 'collapse' || e.colDef.field === 'menu'
    if (notAllowedCell) return
    navigate(`/project/${projectId}/tom/${e.data.tomName.id}?tab=remarks`)
  }

  const columnResizedListener = useCallback((e: ColumnResizedEvent) => {
    const columnState = e.columnApi.getColumnState()
    const withoutSortState = columnState.map(({ sort, ...state }) => state)
    const debounceSave = setTimeout(() => {
      localStorage.setItem('remarkColumnState', JSON.stringify(withoutSortState))
    }, 700)

    return () => {
      clearTimeout(debounceSave)
    }
  }, [])

  const gridReadyListener = useCallback((e: GridReadyEvent) => {
    dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: false, mode: 'create' } }))
    const savedColumnState = localStorage.getItem('remarkColumnState')
    if (savedColumnState) {
      e.columnApi.applyColumnState({
        state: JSON.parse(savedColumnState),
        applyOrder: true,
      })
    }
  }, [])

  useEffect(() => {
    if (deleteRemarkResponse.isSuccess) {
      enqueueSnackbar('Замечание успешно удалено.', { variant: 'success' })
    }
  }, [deleteRemarkResponse])

  const onRowDataUpdatedListener = (e: RowDataUpdatedEvent) => {
    const isSorted = e.columnApi.getColumnState()[0].sort === 'desc'
    const lastRenderedRow = e.api.getDisplayedRowCount()
    const lastFocucedCell = e.api.getFocusedCell()?.rowIndex
    const editedCell = lastFocucedCell === 0 ? lastFocucedCell + 1 : lastFocucedCell
    const editedRowNode = e.api.getDisplayedRowAtIndex(lastFocucedCell!)!
    const firstRow = e.api.getFirstDisplayedRow()
    const createdRowNode = e.api.getDisplayedRowAtIndex(isSorted ? firstRow : lastRenderedRow - 1)!

    if (lastRenderedRow && allowScrolling) {
      e.api.showLoadingOverlay()
      setTimeout(() => {
        if (mode === 'edit' && editedCell) {
          e.api.ensureIndexVisible(editedCell, 'middle')
          e.api.flashCells({ rowNodes: [editedRowNode], flashDelay: 3000, fadeDelay: 1000 })
        } else {
          if (isSorted) {
            e.api.flashCells({ rowNodes: [createdRowNode], flashDelay: 3000, fadeDelay: 1000 })
          } else {
            e.api.ensureIndexVisible(lastRenderedRow - 1, 'bottom')
            e.api.flashCells({ rowNodes: [createdRowNode], flashDelay: 3000, fadeDelay: 1000 })
          }
        }
      }, 250)
      setTimeout(() => {
        e.api.hideOverlay()
        dispatch(setAllowScrolling({ allowScrolling: { allowScrolling: false, mode: 'create' } }))
      }, 500)
    }
  }

  return (
    <>
      <StyledAgGridWrapper style={{ marginTop: '20px' }}>
        <div className='ag-theme-alpine'>
          <AgGridReact
            ref={gridRef}
            onCellClicked={cellClickedListener}
            onGridReady={gridReadyListener}
            overlayNoRowsTemplate='Нет данных для отображения'
            onColumnResized={columnResizedListener}
            onRowDataUpdated={onRowDataUpdatedListener}
            loadingOverlayComponent={Progress}
            getRowId={getRowId}
            columnDefs={columnDef}
            rowData={rowData}
            headerHeight={32}
            detailRowAutoHeight
            rowHeight={DEFAULT_ROW_HEIGHT}
            getRowHeight={getRowHeight}
            rowClassRules={rowClassRules}
            suppressRowTransform={true}
            animateRows={true}
            isFullWidthRow={isFullWidthRow}
            fullWidthCellRenderer={fullWidthCellRenderer}
            suppressDragLeaveHidesColumns={true}
            defaultColDef={{
              cellDataType: false,
              editable: false,
              sortable: false,
              filter: false,
              resizable: true,
            }}
          />
        </div>
      </StyledAgGridWrapper>
      <ConfirmDialog />
    </>
  )
}
