import { Remark, RemarkInfo, RemarkMessages, RemarkStatus } from "../../../../../../api/remarks/types"

export type RemarkColorLabeL = 'gray' | 'red' | 'green' | 'blue' | 'yellow' | 'lightgreen'

export type RemarkStatusRu = 'Создано' | 'Направлено' | 'В работе' | 'Отработано' | 'Закрыто'

export const remarkStatusRuArray: RemarkStatusRu[] = ['Создано', 'Направлено', 'В работе', 'Отработано', 'Закрыто']

export type RemarkMenuTitle = 'Удалить замечание' | 'Редактировать замечание'
export type RemarkTableMenuTitle =
  'Удалить замечание'
  | 'Редактировать замечание'
  | 'Назначить исполнителя'
  | 'Сменить исполнителя'

export type RemarkGroupedTableMenuTitle =
  | 'Назначить ответственного'
  | 'Назначить исполнителя'
  | 'Сменить ответственного'
  | 'Сменить исполнителя'
  | 'Скачать вложения'
  | 'Скачать вложение'

export interface RemarkProps {
  remarkData: Remark
  selectedRemark: number | null
  selectRemark: (id: number, version: number | null, change: number | null, notCancelSelect?: boolean) => void
  openRemarkForm: () => void
  scrollTrigger: boolean
}
export interface RemarkStatusEnToRu extends Record<RemarkStatus, RemarkStatusRu> { }
export const remarkStatusEnToRu: RemarkStatusEnToRu = {
  'submitted': 'Направлено',
  'closed': 'Закрыто',
  'accepted': 'В работе',
  'processed': 'Отработано',
  'created': 'Создано',
}

export interface RemarkStatusRuToEn extends Record<RemarkStatusRu, RemarkStatus> { }
export const remarkStatusRuToEn: RemarkStatusRuToEn = {
  'Направлено': 'submitted',
  'Закрыто': 'closed',
  'В работе': 'accepted',
  'Отработано': 'processed',
  'Создано': 'created',
}

export interface RemarkColorStatus extends Record<RemarkStatus, RemarkColorLabeL> { }
export const remarkColorStatus: RemarkColorStatus = {
  'submitted': 'blue',
  'closed': 'lightgreen',
  'accepted': 'green',
  'created': 'green',
  'processed': 'yellow'
}
