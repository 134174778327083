import { RemarkStatus } from "../../../../api/remarks/types"

export type FilterValues = 'all' | RemarkStatus
export interface RemarkFiltersRuToEn extends Record<RemarkFilters, FilterValues> { }
export interface RemarkFiltersEnToRu extends Record<FilterValues, RemarkFilters> { }
export type RemarkFilters =
  'Все замечания'
  | 'Создано (не направлено)'
  | 'Направлено ответственному'
  | 'Принято в работу'
  | 'Отработано'
  | 'Закрыто'

export const remarkFilters: Array<RemarkFilters> = [
  'Все замечания',
  'Создано (не направлено)',
  'Направлено ответственному',
  'Принято в работу',
  'Отработано',
  'Закрыто',
]

export const remarkFilterValues: RemarkFiltersRuToEn = {
  'Все замечания': 'all',
  'Направлено ответственному': 'submitted',
  'Закрыто': 'closed',
  'Принято в работу': 'accepted',
  'Отработано': 'processed',
  'Создано (не направлено)': 'created',
}

export const remarkFilterValuesRu: RemarkFiltersEnToRu = {
  'all': 'Все замечания',
  'submitted': 'Направлено ответственному',
  'closed': 'Закрыто',
  'accepted': 'Принято в работу',
  'processed': 'Отработано',
  'created': 'Создано (не направлено)',
}